//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';
import PickerColor from '@/components/tools/PickerColor.vue';

export default {
  components: { SectionComponent, PickerColor },

  props: {
    settingOptions: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      formatDateOptions: ['YYYY/MM/DD', 'YYYY/MM/DD HH:mm'],
      expanded: this.$ls.get('uv-exp-item-display-section') ?? false
    };
  },

  computed: {
    showCreatedDate() {
      return this.settingOptions?.show_created_date ?? true;
    },
    formatCreatedDate() {
      return this.settingOptions?.format_created_date || 'YYYY/MM/DD';
    },
    showTitle() {
      return this.settingOptions?.show_title ?? true;
    },
    fontColor() {
      return this.settingOptions?.fontcolor || '#4a86e8';
    },
    showBorder() {
      return this.settingOptions?.show_border ?? true;
    },
    showCategory() {
      return this.settingOptions?.show_category ?? true;
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-item-display-section', this.expanded);
    },
    // ToDo: Update setting options
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    },
    toggleCreatedDate({ target }) {
      this.updateSettingOptions({ show_created_date: target.checked });
    },
    changeFormatCreatedDate(value) {
      this.updateSettingOptions({ format_created_date: value });
    },
    toggleShowTitle({ target }) {
      this.updateSettingOptions({ show_title: target.checked });
    },
    changeFontColor(value) {
      this.updateSettingOptions({ fontcolor: value });
    },
    toggleShowCategory({ target }) {
      this.updateSettingOptions({ show_category: target.checked });
    },
    toggleShowBorder({ target }) {
      this.updateSettingOptions({ show_border: target.checked });
    }
  }
};
