//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PublishInfo from './PublishInfo.vue';
import ItemDisplayOption from './ItemDisplayOption.vue';
import Preview from './Preview.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    PublishInfo,
    ItemDisplayOption,
    Preview
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
    code: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      newCode: {},
      iframeCode: `<iframe src="" style="border: 1px solid #c4c4c4; min-height: 400px;" width=100% height=100%></iframe>`
    };
  },

  computed: {
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById']),

    publishCodeURL() {
      const baseURL = process.env.VUE_APP_PUBLISHED_CODE_BASE_URL;
      return `${baseURL}/codes/${this.code.id}`;
    },
    currentProject() {
      return this.projectById(this.$route.params.projectId);
    },
    currentPage() {
      return this.pageById(this.code.project_id);
    },
    baseSubDomain() {
      return process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
    },
    settingOptions() {
      return this.newCode?.setting_options || {};
    }
  },

  async mounted() {
    this.newCode = { ...this.code };
    this.iframeCode = this.iframeCode.replace(/src="(.*?)"/g, `src="${this.publishCodeURL}"`);
    const that = this;
    window.addEventListener(
      'message',
      function(e) {
        if (e?.data?.height) {
          that.iframeCode = that.iframeCode.replace('height=100%', `height=${e.data.height}px`);
        }
      },
      false
    );
  },

  beforeDestroy() {
    window.removeEventListener('message', null);
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    updateSettingOptions(value) {
      const notifyMessage = {
        success: this.$t('This code setting is updated.'),
        error: this.$t('This code setting update failed.')
      };
      this.updateCode(
        {
          setting_options: { ...this.settingOptions, ...value }
        },
        notifyMessage
      );
    },
    async updateCode(input) {
      const iframeCodeCopy = this.iframeCode;
      this.iframeCode = '';
      try {
        const data = await this.$s.api.page.editCodeById(this.code.project_id, this.code.id, input);
        this.newCode = { ...data };
        this.$notification.success({
          message: this.$t('This code setting is updated.')
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t('This code setting update failed.')
        });
      } finally {
        this.iframeCode = iframeCodeCopy;
      }
    }
  }
};
