//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';

export default {
  components: { SectionComponent },

  props: {
    iframeCode: { type: String, default: '' }
  },

  data() {
    return {
      expanded: this.$ls.get('uv-exp-preview-section') ?? true,
      viewMode: [
        {
          value: 'pc',
          label: this.$t('PC Preview'),
          width: '100%',
          height: '100%'
        },
        {
          value: 'mobile',
          label: this.$t('Mobile Preview'),
          width: '450px',
          height: '896px'
        }
      ]
    };
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-preview-section', this.expanded);
    },
    changeModeIframe({ key }) {
      const mode = this.viewMode.find(elm => elm.value === key);
      const newIframeCode = this.iframeCode
        .replace(/(height=)[0-9]*((px)|%)/g, `height=${mode.height}`)
        .replace(/(width=)[0-9]*((px)|%)/g, `width=${mode.width}`);
      this.$emit('change-iframe-code', newIframeCode);
    }
  }
};
