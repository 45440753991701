var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "95%",
        visible: _vm.visible,
        footer: null,
        "dialog-style": { top: "20px" }
      },
      on: { cancel: _vm.handleCancel }
    },
    [
      _c("br"),
      _c("publish-info", {
        attrs: {
          "iframe-code": _vm.iframeCode,
          "publish-code-url": _vm.publishCodeURL
        }
      }),
      _c("br"),
      _c("item-display-option", {
        attrs: { "setting-options": _vm.settingOptions },
        on: { "update-setting-options": _vm.updateSettingOptions }
      }),
      _c("br"),
      _c("preview", {
        attrs: { "iframe-code": _vm.iframeCode },
        on: {
          "change-iframe-code": function(value) {
            return (_vm.iframeCode = value)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }