var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Item display option"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 0] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Created date setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show created date")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showCreatedDate },
                        on: { change: _vm.toggleCreatedDate }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Format created date")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            value: _vm.formatCreatedDate,
                            disabled: !_vm.showCreatedDate
                          },
                          on: { change: _vm.changeFormatCreatedDate }
                        },
                        _vm._l(_vm.formatDateOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item, attrs: { value: item } },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Title setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show title")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showTitle },
                        on: { change: _vm.toggleShowTitle }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Title color")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.fontColor },
                        on: { change: _vm.changeFontColor }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Item display setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show category tag")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showCategory },
                        on: { change: _vm.toggleShowCategory }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show border")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showBorder },
                        on: { change: _vm.toggleShowBorder }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }