var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Publish info"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 16] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 14 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Publish URL setting")))
              ]),
              _c(
                "a-row",
                {
                  attrs: {
                    gutter: [16, 16],
                    type: "flex",
                    justify: "space-between",
                    align: "middle"
                  }
                },
                [
                  _c("a-col", { attrs: { md: 24, lg: 15 } }, [
                    _c(
                      "div",
                      { staticClass: "content-start" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mr-xs",
                            staticStyle: { "white-space": "nowrap" }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Publish URL")) + " ")]
                        ),
                        _c("a-input", {
                          attrs: { value: _vm.publishCodeUrl, disabled: "" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { md: 24, lg: 9 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-xs",
                          attrs: { type: "primary" },
                          on: { click: _vm.copyPublishURL }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")]
                      ),
                      _c("a-button", { on: { click: _vm.openCode } }, [
                        _vm._v(" " + _vm._s(_vm.$t("Open")) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 10 } },
            [
              _c(
                "div",
                { staticClass: "content-between mb-xs" },
                [
                  _c("span", { staticClass: "body font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("Embed code")))
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.copyIframeCode }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")]
                  )
                ],
                1
              ),
              _c("a-textarea", {
                staticStyle: { width: "100%" },
                attrs: { value: _vm.iframeCode },
                on: { change: _vm.changeIframeCode }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }