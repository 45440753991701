//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';

export default {
  components: { SectionComponent },

  props: {
    iframeCode: { type: String, default: '' },
    publishCodeUrl: { type: String, default: '' }
  },

  data() {
    return {
      expanded: this.$ls.get('uv-exp-publish-info-section') ?? true
    };
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-publish-info-section', this.expanded);
    },
    changeIframeCode({ target }) {
      this.$emit('change-iframe-code', target.value);
    },
    openCode() {
      window.open(this.publishCodeUrl, '_blank');
    },
    copyPublishURL() {
      this.$copyText(this.publishCodeUrl)
        .then(() => {
          this.$message.success(this.$t('Copy completed'));
        })
        .catch(err => {
          console.log(err);
          this.$message.error(this.$t('Copy failed'));
        });
    },
    copyIframeCode() {
      this.$copyText(this.iframeCode)
        .then(() => {
          this.$message.success(this.$t('Copy completed'));
        })
        .catch(err => {
          console.log(err);
          this.$message.error(this.$t('Copy failed'));
        });
    }
  }
};
