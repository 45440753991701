var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-component",
    {
      attrs: { topic: _vm.$t("Preview"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [8, 8], type: "flex", justify: "end" } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 4 } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "label-in-value": "",
                    "default-value": { key: "pc" }
                  },
                  on: { change: _vm.changeModeIframe }
                },
                _vm._l(_vm.viewMode, function(mode) {
                  return _c(
                    "a-select-option",
                    { key: mode.value, attrs: { value: mode.value } },
                    [_vm._v(" " + _vm._s(mode.label) + " ")]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("div", {
        staticClass: "iframe-container",
        domProps: { innerHTML: _vm._s(_vm.iframeCode) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }